import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';
import Loader from '../Loader';
import { isStringEmpty } from '../../utility';
import moment from 'moment';

class News extends Component {
    constructor(props) {
        super(props);
        this.ref = this.props.firebase.news();
        this.unsubscribe = null;
        this.state = {
            news: [],
            fetching: false,
        }
    }
    onCollectionUpdate = (querySnapshot) => {
        const news = [];

        /// querySnapshot.forEach((doc) => {
        const prod = querySnapshot.val();
        if (prod) {
            const newslst = Object.keys(prod).map(key => ({
                ...prod[key],
                id: key,
            }));
            // console.log("news list:- ", newslst.reverse());
            this.setState({
                news: newslst.reverse(),
            });
        }
        //const { clickUrl, title, description, imageUrl } = doc.val();

        //});
        this.setState({
            fetching: false,
        });

    }

    componentDidMount() {
        this.setState({ fetching: true });
        this.unsubscribe = this.ref.on('value', this.onCollectionUpdate);
    }

    deleteNews(data) {
        this.props.firebase.newsEditAdd(data.id).remove().then(() => {
            this.ref.on('value', this.onCollectionUpdate);
        });
        // this.ref.doc(data.id).delete().then(()=>{
        //     this.ref.onSnapshot(this.onCollectionUpdate);
        // });
    }

    renderNews = () => {


        if (this.state.news.length > 0) {
            let news = this.state.news.map(data => {
                return (
                    <li className="slpkgItm" key={"TS" + data.id}>

                        <div className="sr">{data.title}</div>
                        <div className="url">
                            <a href={data.imageUrl} className="button button-link" target="_blank">View</a>
                        </div>

                        <div className="status" style={{ textTransform: "capitalize" }} >
                            {!isStringEmpty(data.status) ? data.status : 'N/A'}
                        </div>
                        <div className="date">
                            {!isStringEmpty(data.publishDate) ? moment(data.publishDate, "MM-DD-YYYY").format("MM/DD/YYYY"): 'N/A'}
                        </div>
                        <div className="action">

                            <Link to={{ pathname: ROUTES.NEWSADDEDIT, state: { news: data } }} className="button button-primary button-small" title="Edit">
                                <span className="skpkgviewall">Edit</span>
                            </Link>
                            {/* <button className="button button-primary button-small" onClick={(e) => {
                                this.deleteNews(data)
                            }}>Delete</button> */}
                        </div>
                    </li>
                    // <div className="post" key={data.id}>
                    //    <h3>{data.title}</h3>
                    //    <p>{data.description}</p>
                    //    <button onClick={() =>{} }>Delete post</button>
                    // </div>
                )
            });
            return news;
        } else {
            return null;
        }
    }
    render() {
        return (
            <main className="container flexbox listPage">
                <section className="staticBdrContainer" style={{ display: "block" }}>
                    <div className="srAddToCart">
                        <div className="addToCartBtnWpr">
                            <Link to={{ pathname: ROUTES.NEWSADDEDIT, state: { news: {} } }} className="button button-primary button-small" title="Edit">
                                {/* <img src={require('../../assets/image/edit.svg')} alt="Add" className="awardIcon" /> */}
                                <span className="skpkgviewall">Add</span>
                            </Link>
                        </div>
                    </div>
                    <div className="listWrapper">
                        <div className="listContent">
                            <div className="listWpr">
                                <div className="listCnt">
                                    <ul className="slPkgUk">
                                        <li className="slpkgHdr" >
                                            <div className="sr" >Title</div>
                                            {/* <div className="srdes" key='1'>Description</div> */}
                                            <div className="url" key='2'>Image</div>
                                            <div className="status" key='3'>Status</div>
                                            <div className="date" key='4'>Publish Date</div>
                                            <div className="action" key='5'></div>
                                        </li>
                                        {this.renderNews()}
                                    </ul>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>
                <Loader isVisible={this.state.fetching} />
                <div>

                </div>
            </main>
        );
    }
}

export default withFirebase(News);