import React from 'react';
import { Link } from 'react-router-dom';
import topapplogo from '../../assets/image/topapplogo.png';
import SignOutButton from '../SignOut';
import * as ROUTES from '../../constants/routes';


import { AuthUserContext } from '../Session';

const Navigation = () => (
  
  <div>
    <AuthUserContext.Consumer>
      {authUser =>
        authUser ? <NavigationAuth /> : null
      }
    </AuthUserContext.Consumer>
  </div>
);

function NavigationAuth(){
  return(<div className="container">
     <header className="fixed-top">
      <nav className="navbar">
        <div className="logo-wrapper">
          <Link to={{ pathname: 'home', state: { slctDate: "" } }} className="navbar-brand" title="Homepage">
            <img src={topapplogo} alt="Dell" style={{width:"100px"}} />
          </Link>
          
        </div>
        <div className="top-nav">
        {/* <Link to={{ pathname: 'push', state: { slctDate: "" } }} className="navbar-brand basket-button button-link inline-block fontBold" title="Pushnotification">
                  Send Push
        </Link> */}
        <div>
          <Link to="/news">news</Link>
        </div>
        <div>
          <Link to="/users">Contact Records</Link>
        </div>
        <div>
          <Link to="/email">Newsletter subscriptions</Link>
        </div>
        <div>
          <Link to="/site-setting">Settings</Link>
        </div>
        <div className="lidivion" ></div>
          <SignOutButton />
          </div>
      </nav>
    </header> 
    
  </div>);
}
export default Navigation;

