export const LANDING ='/';
export const SIGN_IN = '/signin';
export const HOME = '/home';
export const NEWS = '/news';
export const NEWSADDEDIT = '/add';
export const PUSH = "/addpush";
export const PUSHList="/push";
export const USERS="/users";
export const EMAIL = "/email";
export const SITESETTING = "/site-setting";
