import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';
import Loader from '../Loader';
import {isStringEmpty} from '../../utility';

class PushList extends Component {
    constructor(props) {
        super(props);
        this.ref = this.props.firebase.pushData();
        this.unsubscribe = null;
        this.state = {
            pushData: [],
            fetching: false,
        }
    }
    onCollectionUpdate = (querySnapshot) => {
        const push = [];

        /// querySnapshot.forEach((doc) => {
        const prod = querySnapshot.val();
        if (prod) {
            const pushlst = Object.keys(prod).map(key => ({
                ...prod[key],
                id: key,
            }));
            console.log("pushlst",pushlst);
            //console.log("pushlst reverse",pushlst.reverse());
            var temp = pushlst.reverse();
            console.log("temp data:-", temp);
            this.setState({
                pushData: pushlst,
            });
        }
        //const { clickUrl, title, description, imageUrl } = doc.val();

        //});
        this.setState({
            fetching: false,
        });

    }

    componentDidMount() {
        this.setState({ fetching: true });
        this.unsubscribe = this.ref.on('value', this.onCollectionUpdate);
    }

    // deleteNews(data) {
    //     this.props.firebase.newsEditAdd(data.id).remove().then(() => {
    //         this.ref.on('value', this.onCollectionUpdate);
    //     });
    //     // this.ref.doc(data.id).delete().then(()=>{
    //     //     this.ref.onSnapshot(this.onCollectionUpdate);
    //     // });
    // }

    renderPush = () => {
        if (this.state.pushData.length > 0) {
            let pushLst = this.state.pushData.map(data => {
                return (
                    <li className="slpkgItm" key={"TS" + data.id}>

                        <div className="sr">{data.title}</div>
                        <div className="srdes">{data.description}</div>
                        <div className="url">
                        {
                            !isStringEmpty(data.webUrl) ? <a href={data.webUrl} className="button button-link" target="_blank" style={{display: data.webUrl ==="" ? "none": "block"}}>View</a> : 'N/A'
                        }
                            
                        </div>

                        <div className="status">
                            { !isStringEmpty(data.newsId) ? data.newsId : 'N/A'}
                        </div>
                        <div className="status" style={{textTransform: "capitalize"}} >
                            { !isStringEmpty(data.topic) ? data.topic : 'N/A'}
                        </div>
                        <div className="date">
                            { !isStringEmpty(data.pushDate) ? data.pushDate : 'N/A'}
                            </div>
                        {/* <div className="action">

                            <Link to={{ pathname: ROUTES.NEWSADDEDIT, state: { news: data } }} className="button button-primary button-small" title="Edit">
                                <span className="skpkgviewall">Edit</span>
                            </Link>
                            {/* <button className="button button-primary button-small" onClick={(e) => {
                                this.deleteNews(data)
                            }}>Delete</button> }
                        </div> */}
                    </li>
                    // <div className="post" key={data.id}>
                    //    <h3>{data.title}</h3>
                    //    <p>{data.description}</p>
                    //    <button onClick={() =>{} }>Delete post</button>
                    // </div>
                )
            });
            return pushLst;
        } else {
            return null;
        }
    }

    render() {
        return (
            <main className="container flexbox listPage">
                <section className="staticBdrContainer" style={{ display: "block" }}>
                    <div className="srAddToCart">
                        <div className="addToCartBtnWpr">
                            <Link to={{ pathname: ROUTES.PUSH, state: { pushData: {} } }} className="button button-primary button-small" title="Edit">
                                {/* <img src={require('../../assets/image/edit.svg')} alt="Add" className="awardIcon" /> */}
                                <span className="skpkgviewall">Add</span>
                            </Link>
                        </div>
                    </div>
                    <div className="listWrapper">
                        <div className="listContent">
                            <div className="listWpr">
                                <div className="listCnt">
                                    <ul className="slPkgUk">
                                        <li className="slpkgHdr" >
                                            <div className="sr" >Title</div>
                                            <div className="srdes" key='1'>Description</div>
                                            <div className="url" key='2'>Web-Url</div>
                                            <div className="status" key='3'>NewsId</div>
                                            <div className="status" key='4'>Region</div>
                                            <div className="date" key='5'>Publish Date</div>
                                            {/* <div className="action" key='6'></div> */}
                                        </li>
                                        {this.renderPush()}
                                    </ul>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>
                <Loader isVisible={this.state.fetching} />
                <div>

                </div>
            </main>
        );
    }
}

export default withFirebase(PushList);