import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/storage';


// Dev config 
// const config = {
//   apiKey: "AIzaSyBK9jlLsDfdAQqPh2Hq9K3BiqcI9IRoVNI",
//   authDomain: "optimewebpa.firebaseapp.com",
//   projectId: "optimewebpa",
//   databaseURL: "https://optimewebpa-default-rtdb.firebaseio.com/",
//   storageBucket: "optimewebpa.appspot.com",
//   messagingSenderId: "862402072572",
//   appId: "1:862402072572:web:99ebebe131721427f22c79"
// };

// production config 
const config = {
  apiKey: "AIzaSyCb-vidgaFGxgYQ2kq51jVVWe-gIPgyCs4",
  authDomain: "optime-website-cms.firebaseapp.com",
  databaseURL: "https://optime-website-cms-default-rtdb.firebaseio.com",
  projectId: "optime-website-cms",
  storageBucket: "optime-website-cms.appspot.com",
  messagingSenderId: "374454337856",
  appId: "1:374454337856:web:a9daeeffd351d7f6957aa5",
  measurementId: "G-YJB1VS30ZZ",
};

class Firebase {
  constructor() {
    app.initializeApp(config);

    this.auth = app.auth();
    this.db = app.database();
  }


  // *** Auth API ***

  doCreateUserWithEmailAndPassword = (email, password) =>
    this.auth.createUserWithEmailAndPassword(email, password);

  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);

  doSignOut = () => { this.auth.signOut(); window.location.href = 'https://www.cms.optimegroup.co.uk/' };
  news = () => this.db.ref('news')//app.firestore(); //this.db..ref("products");
  users = () => this.db.ref('users');
  email = () => this.db.ref('email');
  newsEditAdd = id => this.db.ref(`news/${id}`);
  storage = () => app.storage();
  doPasswordReset = email => this.auth.sendPasswordResetEmail(email);
  pushData = () => this.db.ref('pushData');
  topBannerRecord = () => this.db.ref('bannertext');




  doPasswordUpdate = password =>
    this.auth.currentUser.updatePassword(password);
}

export default Firebase;
