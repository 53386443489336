import React, { Component } from 'react';
import { withFirebase } from '../Firebase';
import { isEmpty } from '../../utility';
import Loader from '../Loader';

class PushPage extends Component {
    constructor(props) {
        super(props);
        this.ref = this.props.firebase.news();
        this.unsubscribe = null;
        //this.adminPush = admin.initializeApp();
        this.state = {
            title: "",
            description: "",
            newsId: "",
            topic: "general",
            redirectionUrl: "",
            error: "",
            errormessage: "",
            news: [],
            isNews: false,
            isRegion: false,
            isURL: false,
            fetching: false,
            id: "",
            region:[{value:"Austria",name:"Austria"}, {value:"Belgium",name:"Belgium"},{value:"Bulgaria",name:"Bulgaria"} ,{value:"Croatia",name:"Croatia"} , {value:"Cyprus",name:"Cyprus"}, {value:"Czech",name:"Czech"} ,{value:"Republic",name:"Republic"},{value:"Denmark",name:"Denmark"} , {value:"Estonia",name:"Estonia"}, {value:"Finland",name:"Finland"}, {value:"France",name:"France"},{value:"Germany",name:"Germany"}, {value:"Greece",name:"Greece"}, {value:"Hungary",name:"Hungary"}, {value:"Ireland",name:"Ireland"},{value:"Italy",name:"Italy"} , {value:"Latvia",name:"Latvia"},{value:"Lithuania",name:"Lithuania"} , {value:"Luxembourg",name:"Luxembourg"},{value:"Malta",name:"Malta"} , {value:"Montenegro",name:"Montenegro"}, {value:"Netherlands",name:"Netherlands"}, {value:"Poland",name:"Poland"},{value:"Portugal",name:"Portugal"},{value:"Romania",name:"Romania"} , {value:"Serbia",name:"Serbia"},  {value:"Slovenia",name:"Slovenia"}, {value:"Spain",name:"Spain"}, {value:"Sweden",name:"Sweden"}, {value:"United Kingdom",name:"United Kingdom"}],
        }
    }

    onCollectionUpdate = (querySnapshot) => {
        const news = [];

        /// querySnapshot.forEach((doc) => {
        const prod = querySnapshot.val();
        if (prod) {
            const newslst = Object.keys(prod).map(key => ({
                ...prod[key],
                id: key,
            }));
            
            const temp  = newslst.reverse().filter(obj => 
               { 
                   if(obj.status ==='active')
                    {
                        return obj;
                    } 
                });
            
            this.setState({
                news: temp,
            });
        }
        this.setState({
            fetching: false,
        });
    }

    componentDidMount() {
        this.setState({ fetching: true });
        this.unsubscribe = this.ref.on('value', this.onCollectionUpdate);
    }

    renderNews() {
        let news = this.state.news.map((item, i) => {
            return (<option key={i} value={item.id} >{item.title}</option>)

        });
        return news;
    }

    selectedNews = (e) => {
        if (e.target.value !== "select") {
            //console.log("target value", e.target.value);
            this.setState({ newsId: e.target.value, isRegion: true, isNews: false, isURL: true,redirectionUrl:"" });
        } else {
            this.setState({ newsId: "", isNews: false, isRegion: false, isURL: false,redirectionUrl:"", });
        }
    }

    selectedRegion = (e) => {
        console.log("selected region:-",e.target.value);
        if (e.target.value !== "general") {
            console.log("value changed:-",e.target.value);
            this.setState({ topic: e.target.value, isNews: true, isRegion: false, isURL: false});
        } else {
            this.setState({ topic: "", isNews: false, isRegion: false, isURL: false });
        }
    }

    renderRegion() {
        let regionArry = this.state.region.map((obj,i)=>{
            return (<option key={i} value={obj.value}>{obj.name}</option>);
        });
        return regionArry;
    }

    getInputValues = (e) => {
        if(e.target.value.trim() !=="" && e.target.name ==="redirectionUrl")
        {
            this.setState({  isNews: true, isRegion: false, isURL: false,newsId:"" });
        }else if(e.target.value.trim() ==="" && e.target.name ==="redirectionUrl"){
            this.setState({isNews: false});
        }
        this.setState({ [e.target.name]: e.target.value });
    }

    onSubmit = event => {
        event.preventDefault();
        const { title, description, redirectionUrl, newsId, topic } = this.state;

        this.setState({ fetching: true });
        if (title.trim() === '' || topic === "" || description.trim() === "") {
            this.setState({ errormessage: "Title, Description & Region fields are mandatory." });
            this.setState({ fetching: false });
            return;
        } else {
            this.setState({ errormessage: "" });
            this.addPushData();
        }
    }
    addPushData() {
        const { title, description, redirectionUrl, newsId, topic,id } = this.state;
        var date = new Date().getDate(); //Current Date
        var month = new Date().getMonth() + 1; //Current Month
        var year = new Date().getFullYear(); //Current Year
        var hrs = new Date().getHours();
        var mins= new Date().getMinutes();
        var secs = new Date().getSeconds();
        var crnt_dateTime = date + "/" + month + "/" + year +" "+hrs+":"+mins+":"+secs;
       // console.log("currentdate:-",crnt_dateTime);
        const pushData = {
            title: title,
            webUrl: redirectionUrl,
            description: description,
            newsId: newsId==="" ? " ": newsId,
            topic: topic,
            pushDate: crnt_dateTime,
        }
        console.log("pushData",pushData);
        this.props.firebase.pushData().push(pushData).then((data) => {
            //console.log(data);
            this.setState({ fetching: false });
            this.props.history.goBack();
        }).catch(error => {
            this.setState({ fetching: false });
            this.setState({ error });
        });
    }

    render() {
        const { title, description, error, redirectionUrl } = this.state;
        return (
            <section className="staticBdrContainer">
                <div className="loginFormContainer">
                    <form onSubmit={this.onSubmit}>
                        <div className="lgRow">
                            <label htmlFor="title" className="labelTitle">Title <span>(*max 100 words)</span></label>
                            <input
                                id="title"
                                name="title"
                                value={title}
                                onChange={this.getInputValues}
                                type="text"
                                placeholder="Title"
                                className="InputText"
                                maxLength="100"
                                required
                            />
                        </div>
                        <div className="lgRow padTp1_5">
                            <label htmlFor="description" className="labelTitle" >Description <span>(*max 200 words)</span></label>
                            <textarea
                                name="description"
                                value={description}
                                onChange={this.getInputValues}
                                type="text"
                                placeholder="Description"
                                className="InputText"
                                id="description"
                                maxLength="200"
                                required
                            />

                        </div>
                        <div className="lgRow padTp1_5">
                            <label htmlFor="news" className="labelTitle" >Select news</label>

                            <select
                                onChange={this.selectedNews} disabled={this.state.isNews}
                            >
                                <option value="select">Select News</option>
                                {this.renderNews()}

                            </select>
                        </div>
                        <div className="lgRow padTp1_5">
                            <label htmlFor="region" className="labelTitle" >Select Region</label>

                            <select
                                onChange={this.selectedRegion} disabled={this.state.isRegion}>
                                <option value="general">General</option>
                                {this.renderRegion()}

                            </select>
                        </div>
                        <div className="lgRow padTp1_5">
                            <label htmlFor="url" className="labelTitle">URL</label>
                            <input
                                id="url"
                                name="redirectionUrl"
                                value={redirectionUrl}
                                onChange={this.getInputValues}
                                type="text"
                                placeholder="URL"
                                className="InputText"
                                maxLength="100"
                                disabled={this.state.isURL}
                            />
                        </div>

                        <div className="errorMessage">
                            {this.state.errormessage}
                        </div>
                        {/* <div className="lgRow loginbutton">
                            <button className="button button-primary button-login" type="submit">
                                Send Push
                            </button>
                        </div> */}


                        {error && <p>{error.message}</p>}
                    </form>
                </div>

                <Loader isVisible={this.state.fetching} />
            </section>
        )
    }

}
export default withFirebase(PushPage);
