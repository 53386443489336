import React, { Component, useState } from "react";
import { withFirebase } from "../Firebase";


class SiteSetting extends Component  {

    constructor(props) {
        super(props);
            this.ref = this.props.firebase.topBannerRecord();
            this.state = {
                topBanner: false,
                text: ""
            }
    }

    componentDidMount() {
        this.ref.on('value', this.snapShotFun);
    }


    snapShotFun = (querySnapshot) => {
        console.log ("SETTINGS VAL:", querySnapshot.val());

        // const value = querySnapshot.val();
        // this.setState({topBanner: value});
        // this.setState({ topBanner: value });
        // console.log(value);
    }
   
    setCopy = (event) => {
        this.setState({ copy: event.target.value });
    }

    handleChange = async () => {
        await this.setState({ topBanner: !this.state.topBanner });
        this.ref.set({
            bannerText: this.state.copy,
            topBanner: this.state.topBanner
        });
    } 

    handleSaveText = async () => {
        this.ref.set({bannerText: this.state.copy, topBanner: this.state.topBanner});
    }

    render () {
       
        return (
            <main className="container site-settings">
                <div className="settings">
                    <label>
                        <input type="checkbox" name="topbanner" checked={this.state.topBanner} onChange={() => this.handleChange() } />
                        <span className="checkmark"></span>
                        Enable top banner
                    </label>

                    <div className="lgRow pt-4">
                        <label htmlFor="copy" className="labelTitle">
                            Top Banner Text
                        </label>
                        <input
                            id="copy"
                            name="copy"
                            value={this.state.copy}
                            onChange={this.setCopy}
                            type="text"
                            placeholder="Title"
                            className="InputText"
                            maxLength="100"
                            required
                        />
                    </div>

                    <div className="lgRow loginbutton pt-4">
                        <button className="button button-primary button-login" type="button" onClick={() => this.handleSaveText()}>Update</button></div>
                </div>
            </main>
        ) 
    }
}

export default withFirebase(SiteSetting);