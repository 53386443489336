import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';

import Navigation from '../Navigation';
import SignInPage from '../SignIn';
import News from '../News';
import NewsAddEdit from '../NewsAddEdit';
import HomePage from '../Home';
import PushPage from '../PushPage';
import '../../assets/css/login.css';
import '../../assets/css/App.css';
import '../../assets/css/button.css';
import '../../assets/css/navigation.css';
import '../../assets/css/listpage.css';
import * as ROUTES from '../../constants/routes';
import { withAuthentication } from '../Session';
import PushList from '../PushList';
import Users from '../Users';
import Email from '../Email';
import SiteSetting from '../SiteSetting';

const App = () => (
  <Router>
    <div className="contentWrapper">
      <Navigation />
      {/* <Route exact path={ROUTES.LANDING} component={LandingPage} /> */}
      {/* <Route  path={ROUTES.SIGN_UP} component={SignUpPage} /> */}
      <Route exact path={ROUTES.SIGN_IN} component={SignInPage} />
      <Route exact path={ROUTES.LANDING} component={SignInPage} />
      <Route  path={ROUTES.HOME} component={HomePage} />
      <Route  path={ROUTES.NEWS} component={News} />
      <Route path={ROUTES.NEWSADDEDIT} component={NewsAddEdit}/>

      <Route path={ROUTES.PUSH} component={PushPage}/>
      <Route path={ROUTES.PUSHList} component={PushList}/>
      <Route path={ROUTES.USERS} component={Users} />
      <Route path={ROUTES.EMAIL} component={Email} />
      <Route path={ROUTES.SITESETTING} component={SiteSetting} />

    </div>
  </Router>
);

export default withAuthentication(App);
