import React, { Component } from "react";
import { withFirebase } from "../Firebase";
import { isEmpty } from "../../utility";
import Loader from "../Loader";
import CKEditor from "react-ckeditor-component";
import moment from "moment";

class NewsAddEdit extends Component {
  constructor(props) {
    super(props);
    this.ref = this.props.firebase.news();
    this.uploadFile = this.props.firebase.storage();
    this.unsubscribe = null;
    this.state = {
      id: "",
      title: "",
      slug: "",
      authorName: "Sophie Behan",
      authorDesignation: "Marketing Assistant",
      //   authorImage: "",
      imageUrl: "",
      description: "",
      status: "draft",
      authorUrl: "",
      url: "",
      publishDate: moment().format("MM/DD/YYYY hh:mm:ss"),
      errormessage: "",
      error: null,
      fetching: false,
      isDraftDisable: false,
      isActiveDisable: false,
      isInactiveDisable: false,
      btnName: "Add",
      category: "companyNews",
      statusArry: [
        { id: "active", name: "Active" },
        { id: "inactive", name: "Inactive" },
        { id: "draft", name: "Draft" },
      ],
      categoryArry: [
        { id: "companyNews", name: "Company News" },
        { id: "industryNews", name: "Industry News" },
      ],
      formState: true,
      seoState: {
        metaTitle: "",
        metaDescription: "",
        metaKeyword: "",
        metaImageUrl: "",
      }
    };
  }


  makeSlugByTitle(slug) {
    var slug = slug.toLowerCase();
    slug = slug.replace(/[^a-z0-9 -]/g, "");
    slug = slug.replace(/\s+/g, "-");
    return slug;
  }

  getInputValues = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };


  getInputValuesSEO = (e) => {
    this.setState({ seoState: { ...this.state.seoState, [e.target.name]: e.target.value }})
  };

  onChangeHandler = (event) => {
    //console.log(event.target.files[0]);
    this.setState({ [event.target.name]: event.target.files[0] });
    this.setState({ url: "" });
  };

  uploadImage = () => {
    const {
      id,
      title,
      authorName,
      authorDesignation,
      //   authorImage,
      imageUrl,
      description,
      status,
      category,
      url,
      authorUrl,
      publishDate,
      seoState
    } = this.state;

    // var date = new Date().getDate(); //Current Date
    // var month = new Date().getMonth() + 1; //Current Month
    // var year = new Date().getFullYear(); //Current Year
    // var hrs = new Date().getHours();
    // var mins = new Date().getMinutes();
    // var secs = new Date().getSeconds();
    var dateTime = moment().format("MM/DD/YYYY hh:mm:ss"); //date + "/" + month + "/" + year + " " + hrs + ":" + mins + ":" + secs;
    // console.log();

    if (url === "") {
      // console.log("add or edit image uploaded");
      var name = imageUrl.name.split(".");
      var imageType = "image/" + name[1];
      var metadata = {
        contentType: imageType,
      };

      // console.log(metadata);
      const uploadImg = this.uploadFile
        .ref(`images/${imageUrl.name}`)
        .put(imageUrl, metadata);

      uploadImg.on(
        "state_changed",
        (snapshot) => {},
        (error) => {
          this.setState({ fetching: false });
          // console.log(error);
        },
        () => {
          this.uploadFile
            .ref("images")
            .child(imageUrl.name)
            .getDownloadURL()
            .then((url, authorUrl) => {
              //  console.log("")
              const news = {
                title: title,
                slug: this.makeSlugByTitle(title),
                authorName: authorName,
                authorDesignation: authorDesignation,
                // authorImage: authorUrl !== "" ? authorUrl : authorImage,
                imageUrl: url !== "" ? url : imageUrl,
                description: description,
                status: status,
                category: category,
                seoState: seoState,
                publishDate:
                  publishDate === "" && status === "active"
                    ? dateTime
                    : publishDate,
              };

              if (seoState?.metaKeyword !== "" || seoState?.metaDescription !== "" ) {
                news.seoState = seoState;
              }

              if (id === "") {
                //  console.log("add record");
                this.props.firebase
                  .news()
                  .push(news)
                  .then((data) => {
                    // console.log(data);
                    this.setState({ fetching: false });
                    this.props.history.goBack();
                  })
                  .catch((error) => {
                    this.setState({ fetching: false });
                    this.setState({ error });
                  });
              } else {
                //  console.log("edit record");
                this.props.firebase
                  .newsEditAdd(id)
                  .set(news)
                  .then((data) => {
                    // console.log(data);
                    this.setState({ fetching: false });
                    this.props.history.goBack();
                  })
                  .catch((error) => {
                    this.setState({ fetching: false });
                    this.setState({ error });
                  });
              }
            });
        }
      );
    } else {
      //console.log("edit record");
      
      const product = {
        title: title,
        slug: this.makeSlugByTitle(title),
        authorName: authorName,
        authorDesignation: authorDesignation,
        // authorImage: authorUrl !== "" ? authorUrl : authorImage,
        imageUrl: url !== "" ? url : imageUrl,
        description: description,
        status: status,
        category: category,
        publishDate:
          publishDate === "" && status === "active" ? dateTime : publishDate,
      };


      if (seoState?.metaKeyword !== "" || seoState?.metaDescription !== "") {
        product.seoState = seoState;
      }
      console.log("EDIT", product)
      this.props.firebase
        .newsEditAdd(id)
        .set(product)
        .then((data) => {
          //console.log(data);
          this.setState({ fetching: false });
          this.props.history.goBack();
        })
        .catch((error) => {
          this.setState({ fetching: false });
          this.setState({ error });
        });
    }
  };

  onSubmit = (event) => {
    var dateTime = moment().format("MM/DD/YYYY hh:mm:ss");
    // this.setState({ publishDate: dateTime });
    event.preventDefault();
    const {
      title,
      authorName,
      authorDesignation,
      //   authorImage,
      imageUrl,
      description,
      url,
      publishDate,
      //   authorUrl,
      seoState
    } = this.state;
    let image = imageUrl === "" ? url : imageUrl;
    // let authorImg = authorUrl === "" ? authorUrl : authorImage;
    // console.log("imageUrl", image);
    //console.log("url", url);
    this.setState({ fetching: true });
    if (
      title.trim() === "" ||
      authorName.trim() === "" ||
      authorDesignation.trim() === "" ||
      image === "" ||
      description.trim() === "" ||
      publishDate === ""
    ) {
      this.setState({ errormessage: "All fields are mandatory." });
      this.setState({ fetching: false });
      return;
    } else {
      //   this.setState({ publishDate: dateTime });
      this.setState({ errormessage: "" });
      this.uploadImage();
    }
  };
  componentDidMount() {
    if (!isEmpty(this.props.location.state.news)) {
      //console.log(this.props.location.state.product);
      let records = this.props.location.state.news;
      var dateTime = moment().format("MM/DD/YYYY hh:mm:ss");
      this.setState({
        id: records.id,
        title: records.title,
        authorName: records.authorName,
        authorDesignation: records.authorDesignation,
        description: records.description,
        authorUrl: records.authorUrl,
        url: records.imageUrl,
        status: records.status,
        category: records.category,
        
        isDraftDisable:
          records.status === "active" || records.status === "inactive"
            ? true
            : false,
        isActiveDisable: records.status === "inactive" ? true : false,
        isInactiveDisable: false,
        btnName: "Update",
        publishDate:
          records.publishDate === "" && records.status === "active"
            ? dateTime
            : records.publishDate,
      });

      if (records?.seoState) {
        this.setState({ seoState: records.seoState });
      }

    } else {
      //console.log("add record");
    }
  }
  selectedStatus = (e) => {
    // console.log(e.target.value);
    this.setState({ status: e.target.value });
  };
  renderStatus() {
    let status = this.state.statusArry.map((item, i) => {
      if (this.state.status === item.id) {
        //  console.log("inside if condition");
        return (
          <option key={i} value={item.id} defaultValue>
            {item.name}
          </option>
        );
      } else {
        //console.log("inside else condition");
        return (
          <option
            key={i}
            value={item.id}
            disabled={
              item.id === "active"
                ? this.state.isActiveDisable
                : item.id === "inactive"
                ? this.state.isInactiveDisable
                : this.state.isDraftDisable
            }
          >
            {item.name}
          </option>
        );
      }
    });
    return status;
  }

  selectedCategory = (e) => {
    // console.log(e.target.value);
    this.setState({ category: e.target.value });
  };
  renderCategory() {
    let category = this.state.categoryArry.map((item, i) => {
      if (this.state.category === item.id) {
        //  console.log("inside if condition");
        return (
          <option key={i} value={item.id} defaultValue>
            {item.name}
          </option>
        );
      } else {
        //console.log("inside else condition");
        return (
          <option
            key={i}
            value={item.id}
            // disabled={
            //   item.id === "active"
            //     ? this.state.isActiveDisable
            //     : item.id === "inactive"
            //     ? this.state.isInactiveDisable
            //     : this.state.isDraftDisable
            // }
          >
            {item.name}
          </option>
        );
      }
    });
    return category;
  }

  afterPaste = (evt) => {
    //console.log("afterPaste event called with event info: ", evt.editor.getData());
    var newContent = evt.editor.getData();
    // console.log("data onchnage",newContent);
    this.setState({ description: newContent });
  };
  onChange = (evt) => {
    // console.log("onChange fired with event info: ", evt);
    var newContent = evt.editor.getData();
    //console.log("data onchnage",newContent);
    this.setState({ description: newContent });
  };

  render() {
    const {
      title,
      authorName,
      authorDesignation,
      description,
      clickUrl,
      error,
      errormessage,
      status,
      seoState,
    } = this.state;
    // console.log("status", status);
    return (
      <section className="staticBdrContainer">
        <div className="loginFormContainer">
          <form onSubmit={this.onSubmit}>
            <div className="lgRow">
              <label htmlFor="title" className="labelTitle">
                Title
              </label>
              <input
                id="title"
                name="title"
                value={title}
                onChange={this.getInputValues}
                type="text"
                placeholder="Title"
                className="InputText"
                maxLength="100"
                required
              />
            </div>
            {/* <div className="lgRow">
              <label htmlFor="authorName" className="labelTitle">
                Author name
              </label>
              <input
                id="authorName"
                name="authorName"
                value={authorName}
                onChange={this.getInputValues}
                type="text"
                placeholder="Author name"
                className="InputText"
                maxLength="100"
                required
              />
            </div>
            <div className="lgRow">
              <label htmlFor="authorDesignation" className="labelTitle">
                Author designation
              </label>
              <input
                id="authorDesignation"
                name="authorDesignation"
                value={authorDesignation}
                onChange={this.getInputValues}
                type="text"
                placeholder="Author designation"
                className="InputText"
                maxLength="100"
                required
              />
            </div> */}
            {/* <div className="lgRow padTp1_5">
              <label htmlFor="authorImage" className="labelTitle">
                Upload Author Image
              </label>

              <input
                name="authorImage"
                onChange={this.onChangeHandler}
                type="file"
                placeholder="Description"
                className="input"
                id="authorImage"
                accept="image/*"
              />
            </div> */}
            <div className="lgRow padTp1_5">
              <label htmlFor="description" className="labelTitle">
                Description
              </label>
              <CKEditor
                name="description"
                activeClass="p10"
                content={this.state.description}
                events={{
                  afterPaste: this.afterPaste,
                  change: this.onChange,
                }}
              />
            </div>
            <div className="lgRow padTp1_5">
              <label htmlFor="status" className="labelTitle">
                Status
              </label>

              <select onChange={this.selectedStatus} defaultValue={status}>
                {this.renderStatus()}
              </select>
            </div>
            <div className="lgRow padTp1_5">
              <label htmlFor="category" className="labelTitle">
                Category
              </label>

              <select onChange={this.selectedCategory}>
                {this.renderCategory()}
              </select>
            </div>
            <div className="lgRow padTp1_5">
              <label htmlFor="imageUrl" className="labelTitle">
                Upload Image
              </label>

              <input
                name="imageUrl"
                onChange={this.onChangeHandler}
                type="file"
                placeholder="Description"
                className="input"
                id="imageUrl"
                accept="image/*"
              />
            </div>

            <br />
            <div className="lgRow">
              <label htmlFor="metaKeyword" className="labelTitle">
                Meta Keywords
              </label>
              <input
                id="metaKeyword"
                name="metaKeyword"
                value={seoState?.metaKeyword}
                onChange={this.getInputValuesSEO}
                type="text"
                placeholder="Meta Keywords"
                className="InputText"
                maxLength="100"
              />
            </div>

            <div className="lgRow padTp1_5">
              <label htmlFor="metaDescription" className="labelTitle">
                Meta Description
              </label>
              <input
                id="metaDescription"
                name="metaDescription"
                value={seoState?.metaDescription}
                onChange={this.getInputValuesSEO}
                type="text"
                placeholder="Meta Description"
                className="InputText"
                maxLength="100"
              />
            </div>
            <div className="lgRow padTp1_5"></div>
            <div className="errorMessage">{this.state.errormessage}</div>
            <br />
            <div className="lgRow loginbutton">
              <button
                className="button button-primary button-login"
                type="submit"
              >
                {this.state.btnName}
              </button>
            </div>

            {error && <p>{error.message}</p>}
          </form>
        </div>

        <Loader isVisible={this.state.fetching} />
      </section>
    );
  }
}

export default withFirebase(NewsAddEdit);
