export const isEmpty = (obj) => {
    for (var key in obj) {
        if (obj.hasOwnProperty(key))
            return false;
    }
    return true;
}

export const isStringEmpty = (str) => {
    return (!str || 0 === str.trim().length);
}